@charset 'UTF-8';
@use './modules/global' as *;



// 新着情報
ul.info-news-list {
  width: 100%;

  li.news-container {
    border-bottom: 1px dotted #BAAF92;
    .news-body {
      padding: 1em 2em 1em 1em;
    }
  }

  a {
    &.news-anchor {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 1em 3em 1em 1em;
      position: relative;
      transition: background-color $base-transition;

      @include hover() {
        background-color: $uno;
        text-decoration: none;
      }

      &.unlink {
        pointer-events: none;
        &::after {
          content: none;
        }
      }

      .date {
        color: $accent;
        font-weight: 700;
      }

      .title {
        flex: 0 0 100%;
        margin: .5em 0 0;
      }
    }
    // icon
    &::after {
      content: '';
      background-image: set-svg($arrow-right, $gray-600);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1em;
      height: 1em;
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }
    &[target="_blank"]:not(a[href$=".pdf"]) {
      &::after {
        background-image: set-svg($open-new-window, $gray-600);
        width: 1.25em;
        height: 1.25em;
      }
    }
    &[href$=".pdf"] {
      &::after {
        background-image: set-svg($pdf, $red);
      }
    }
  }
}

// Category
.news-category {
  margin-left: 1em;

  .category-item {
    display: inline-block;
    border-radius: 3px;
    font-size: .75em;
    line-height: 1;
    padding: .5em .5em;
  }
}

.category-inner {
  span[class*="category-style"] {
    background-color: transparent;
    color: inherit;
    display: inline-block;
    font-weight: 500;
    white-space: nowrap;
  }

  span[class*="category-style-1"] {
    background-color: $base;
    color: $white;
  }

  span[class*="category-style-2"] {
    background-color: #f97800;
    color: $white;
  }

  span[class*="category-style-3"] {
    background-color: #007fff;
    color: $white;
  }

  span[class*="category-style-4"] {
    background-color: #59b201;
    color: $white;
  }

  span[class*="category-style-5"] {
    background-color: #c41012;
    color: $white;
  }

  span[class*="category-style-6"] {
    background-color: #6413C6;
    color: $white;
  }

  span[class*="category-style-7"] {
    background-color: #2E8B57;
    color: $white;
  }

  span[class*="category-style-8"] {
    background-color: #C41111;
    color: $white;
  }

  span[class*="category-style-9"] {
    background-color: #B2AF10;
    color: $white;
  }
}



// 一覧をみるボタン
.news-btn-wrapper {
  margin: 2em 0 0;
  text-align: center;
}

.news-btn-column {
  a {
    background-color: $white;
    border: 1px solid $gray-500;
    border-radius: 2em;
    color: $gray-700;
    display: inline-block;
    padding: .5em 2em;
    transition: background-color $base-transition;
    @include hover() {
      background-color: $gray-200;
      text-decoration: none;
    }
  }
}





// タブ
.news-tab-wrapper {
  width: 100%;
}



.news-tab-labels {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
}



.news-tab-label-column {
  flex: 0 0 auto;
  position: relative;
  &:not(:first-child) {
    margin-left: .5em;
  }
}



// Button
.news-tab-label-item {
  background-color: $white;
  border: 1px solid $gray-800;
  border-radius: 2em;
  cursor: pointer;
  color: $gray-dark;
  display: block;
  font-size: .875em;
  padding: .75em 1em;
  text-align: center;
  transition: background-color $base-transition;
  width: 100%;
  @include hover() {
    background-color: $gray-800;
    color: $white;
  }
  &[aria-selected="true"] {
    background-color: $gray-800;
    color: $white;
  }
}



.news-tab-contents {
  position: relative;
  margin: 1em 0 0;
}

.news-tab-contents-item {
  animation: show .4s linear 0s;
}
