@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
ul.info-news-list {
  width: 100%;
}
ul.info-news-list li.news-container {
  border-bottom: 1px dotted #BAAF92;
}
ul.info-news-list li.news-container .news-body {
  padding: 1em 2em 1em 1em;
}
ul.info-news-list a.news-anchor {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 1em 3em 1em 1em;
  position: relative;
  transition: background-color 0.2s ease-out;
}
@media (hover: hover) and (pointer: fine) {
  ul.info-news-list a.news-anchor:hover {
    background-color: #fff6d7;
    text-decoration: none;
  }
}
ul.info-news-list a.news-anchor.unlink {
  pointer-events: none;
}
ul.info-news-list a.news-anchor.unlink::after {
  content: none;
}
ul.info-news-list a.news-anchor .date {
  color: #ef8c0f;
  font-weight: 700;
}
ul.info-news-list a.news-anchor .title {
  flex: 0 0 100%;
  margin: 0.5em 0 0;
}
ul.info-news-list a::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M21.7,12.8C21.8,12.8,21.8,12.8,21.7,12.8c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-7.7-7.7c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l5.1,5.1H3.5C2.7,10.5,2,11.2,2,12s0.7,1.5,1.5,1.5h13.3l-5.1,5.1c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4l7.7-7.7C21.6,13,21.7,12.9,21.7,12.8z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
}
ul.info-news-list a[target=_blank]:not(a[href$=".pdf"])::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
  width: 1.25em;
  height: 1.25em;
}
ul.info-news-list a[href$=".pdf"]::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dc362e"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}

.news-category {
  margin-left: 1em;
}
.news-category .category-item {
  display: inline-block;
  border-radius: 3px;
  font-size: 0.75em;
  line-height: 1;
  padding: 0.5em 0.5em;
}

.category-inner span[class*=category-style] {
  background-color: transparent;
  color: inherit;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
}
.category-inner span[class*=category-style-1] {
  background-color: #61bd64;
  color: #fff;
}
.category-inner span[class*=category-style-2] {
  background-color: #f97800;
  color: #fff;
}
.category-inner span[class*=category-style-3] {
  background-color: #007fff;
  color: #fff;
}
.category-inner span[class*=category-style-4] {
  background-color: #59b201;
  color: #fff;
}
.category-inner span[class*=category-style-5] {
  background-color: #c41012;
  color: #fff;
}
.category-inner span[class*=category-style-6] {
  background-color: #6413C6;
  color: #fff;
}
.category-inner span[class*=category-style-7] {
  background-color: #2E8B57;
  color: #fff;
}
.category-inner span[class*=category-style-8] {
  background-color: #C41111;
  color: #fff;
}
.category-inner span[class*=category-style-9] {
  background-color: #B2AF10;
  color: #fff;
}

.news-btn-wrapper {
  margin: 2em 0 0;
  text-align: center;
}

.news-btn-column a {
  background-color: #fff;
  border: 1px solid #adb5bd;
  border-radius: 2em;
  color: #495057;
  display: inline-block;
  padding: 0.5em 2em;
  transition: background-color 0.2s ease-out;
}
@media (hover: hover) and (pointer: fine) {
  .news-btn-column a:hover {
    background-color: #e9ecef;
    text-decoration: none;
  }
}

.news-tab-wrapper {
  width: 100%;
}

.news-tab-labels {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
}

.news-tab-label-column {
  flex: 0 0 auto;
  position: relative;
}
.news-tab-label-column:not(:first-child) {
  margin-left: 0.5em;
}

.news-tab-label-item {
  background-color: #fff;
  border: 1px solid #343a40;
  border-radius: 2em;
  cursor: pointer;
  color: #333;
  display: block;
  font-size: 0.875em;
  padding: 0.75em 1em;
  text-align: center;
  transition: background-color 0.2s ease-out;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .news-tab-label-item:hover {
    background-color: #343a40;
    color: #fff;
  }
}
.news-tab-label-item[aria-selected=true] {
  background-color: #343a40;
  color: #fff;
}

.news-tab-contents {
  position: relative;
  margin: 1em 0 0;
}

.news-tab-contents-item {
  animation: show 0.4s linear 0s;
}